import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class ServicioLogic {
    static instance = null;
    static createInstance() {
        var object = new ServicioLogic();
        return object;
    }

    static getInstance() {
        if (!ServicioLogic.instance) {
            ServicioLogic.instance = ServicioLogic.createInstance();
        }
        return ServicioLogic.instance;
    }

    constructor() {
    }

    async listar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/servicio/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ServicioLogic] listar()::ERROR >>> "+error);
        }
    }

    async crear(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objRequest.fileImagen);
            formData.append('nombre', objRequest.nombre);
            formData.append('descripcionCorta', objRequest.descripcionCorta);
            formData.append('descripcionLarga', objRequest.descripcionLarga);

            var urlRequest = ENV_APIPATH+"/servicio/crear";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ServicioLogic] crear()::ERROR >>> "+error);
        }
    }

    async editar(objServicio){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objServicio.fileImagen);
            formData.append('id', objServicio.id);
            formData.append('nombre', objServicio.nombre);
            formData.append('descripcionCorta', objServicio.descripcionCorta);
            formData.append('descripcionLarga', objServicio.descripcionLarga);

            var urlRequest = ENV_APIPATH+"/servicio/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ServicioLogic] editar()::ERROR >>> "+error);
        }
    }

    async detalle(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/servicio/detalle";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ServicioLogic] detalle()::ERROR >>> "+error);
        }
    }

    async borrar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/servicio/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ServicioLogic] borrar()::ERROR >>> "+error);
        }
    }
}

const instance = ServicioLogic.getInstance();
export default instance;